import './styles/App.css';
import twitterLogo from './assets/twitter-logo.svg';
import React, { useEffect, useState } from "react";
import NFTCollection from './utils/NFTCollection.json';
import { ethers } from "ethers";

// Constants
const TWITTER_HANDLE = 'neefly21';
const TWITTER_LINK = `https://twitter.com/${TWITTER_HANDLE}`;
const OPENSEA_LINK = '';
const TOTAL_MINT_COUNT = 50;
const CONTRACT_ADDRESS = "0x137FE63ce0cd26C8117A209D3C784cAEf4547e6C";

const App = () => {
  const [currentAccount, setCurrentAccount] = useState("");
  const [nftsMinted, setNFTsMinted] = useState(0);
  const [latestMintURL, setLatestMintURL] = useState("");
  const [isLoading, setLoadingState] = useState(false);
  
  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have metamask!");
      return;
    } else {
      console.log("We have the ethereum object", ethereum);
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account:", account);
      setCurrentAccount(account);
      setupEventListener();
    } else {
      console.log("No authorized account found");
    }
  }

  const checkNFTsMinted = async () => {

  }

  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        alert("Get MetaMask!");
        return;
      }

      let chainId = await ethereum.request({ method: 'eth_chainId' });
      console.log("Connected to chain " + chainId);

      // String, hex code of the chainId of the Rinkebey test network
      const goerliChainId = "0x5"; 
      if (chainId !== goerliChainId) {
        alert("You are not connected to the Goerli Test Network!");
        return;
      }

      const accounts = await ethereum.request({ method: "eth_requestAccounts" });

      console.log("Connected", accounts[0]);
      setCurrentAccount(accounts[0]); 
      setupEventListener();
    } catch (error) {
      console.log(error);
    }
  }

  const setupEventListener = async () => {
  
    try {
      const { ethereum } = window;
  
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, NFTCollection.abi, signer);
  
        connectedContract.on("NewNFTMinted", (from, tokenId) => {
          console.log(from, tokenId.toNumber())
          setLatestMintURL("https://testnets.opensea.io/assets/goerli/" + CONTRACT_ADDRESS + "/" + tokenId.toNumber());
        });

        console.log("Set up smart contract listener!");

      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error)
    }
  }

  const askContractToMintNft = async () => {
    setLoadingState(true);
    try {
      const { ethereum } = window;
  
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, NFTCollection.abi, signer);
  
        console.log("Going to pop wallet now to pay gas...")
        let nftTxn = await connectedContract.makeAnNFT();

        console.log("Mining...please wait.")
        await nftTxn.wait();

        console.log(nftTxn);
        console.log(`Mined, see transaction: https://goerli.etherscan.io/tx/${nftTxn.hash}`);
        setLoadingState(false);
      } else {
        console.log("Ethereum object doesn't exist!");
        setLoadingState(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingState(false);
    }
  }

  // Render Methods
  const renderNotConnectedContainer = () => (
    <button onClick={connectWallet} className="cta-button connect-wallet-button">
      Connect to Wallet
    </button>
  );

  const renderLoading = () => (
    <svg xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(50 42)">
        <g transform="scale(0.8)">
          <g transform="translate(-50 -50)">
            <polygon fill="#e15b64" points="72.5 50 50 11 27.5 50 50 50">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 38.5;360 50 38.5" keyTimes="0;1"></animateTransform>
            </polygon>
            <polygon fill="#f8b26a" points="5 89 50 89 27.5 50">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 27.5 77.5;360 27.5 77.5" keyTimes="0;1"></animateTransform>
            </polygon>
            <polygon fill="#abbd81" points="72.5 50 50 89 95 89">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 72.5 77.5;360 72 77.5" keyTimes="0;1"></animateTransform>
            </polygon>
          </g>
        </g>
      </g>
    </svg>
  );

  useEffect(() => {
    checkIfWalletIsConnected();
  }, [])

  return (
    <div className="App">
      <div className="container">
        <div className="header-container">
          <p className="header gradient-text">My NFT Collection</p>
          <p className="sub-text">
            Connect your wallet to mint a unique blockchain madlib!
          </p>
          {currentAccount === "" ? (
            renderNotConnectedContainer()
          ) : (
            <button onClick={askContractToMintNft} className="cta-button connect-wallet-button" disabled={isLoading}>
              Mint NFT
            </button>
          )}
        </div>
          
        <div style={{textAlign: "center", marginLeft: "12rem"}}>
          {isLoading ? renderLoading() : null}
        </div>

        <div>
        {latestMintURL !== "" ?
            <a target="_target" href={latestMintURL} style={{color: "white", marginTop: "1rem"}}>
              View on Opensea
            </a>
          :null}
        </div>
        <div className="footer-container">
          <img alt="Twitter Logo" className="twitter-logo" src={twitterLogo} />
          <a
            className="footer-text"
            href={TWITTER_LINK}
            target="_blank"
            rel="noreferrer"
          >{`built by @${TWITTER_HANDLE}`}</a>
        </div>
      </div>
    </div>
  );
};

export default App;


